import React from 'react';
import { graphql } from 'gatsby';

import Category from '../../brew-components/Category';

export default function ActiveCategory({ data, pageContext }) {
  const apps = data.allBrew.edges;
  return <Category apps={apps} category={pageContext.category}></Category>;
}

export const query = graphql`
  query {
    allBrew(
      limit: 20
      filter: { updates: { gte: 0 } }
      sort: { fields: updates, order: DESC }
    ) {
      edges {
        node {
          id
          status
          homepage
          name
          timestamp
          token
          url
          version
          stats {
            _30
            _365
            _90
          }
          prevStats {
            _30
            _365
            _90
          }
        }
      }
    }
  }
`;
